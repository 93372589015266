import {
  GEOCODING,
  HttpError,
  IGNITION_HISTORY,
  OBJECTS,
  OBJECTS_ALL,
  OBJECTS_EXTENDED_DATA,
  OBJECTS_GROUPS,
  ROAD_CARD,
  VEHICLE_TYPES,
} from 'common';
import { Status } from 'components/common/Status';
import queryClient from 'lib/react-query';
import {
  ControllsHistoryResponseDto,
  UpdateControllsIgnitionDto,
} from 'modules/objects/dtos/ControllsHistory.dto';
import { RoadCardPayload } from 'modules/objects/dtos/GetRoadCard.dto';
import { useTranslation } from 'react-i18next';
import { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useToast } from 'toast';

import { requests } from '../api';
import {
  GeocodingResponseDto,
  getRoadCardResponseDto,
  ObjectsExtendedDataDto,
  ObjectsExtendedDataResponseDto,
  ObjectsParamsDto,
  ObjectsResponseDto,
  UpdateObjectDto,
  UpdateObjectVRNDto,
} from '../dtos';
import { ExtendedDataOptionsDto } from '../dtos/ObjectsExtendedData.dto';
import { ObjectsResponsePrevDto } from '../dtos/ObjectsResponse.dto';
import { GeocodingParams } from '../types';

interface ErrorType {
  status: number;
  logged: boolean;
  message: string;
}

export const getAllObjects = (
  isAutomaticRefresh?: boolean | undefined,
  automaticRefreshTime?: number,
  config: UseQueryOptions<ObjectsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [OBJECTS, OBJECTS_ALL],
  queryFn: () => requests.getObjects(),
  ...(isAutomaticRefresh &&
    automaticRefreshTime && {
      refetchInterval: automaticRefreshTime * 1000,
    }),
  ...config,
});

export const getGroupObjects = (
  groupId?: string,
  config: UseQueryOptions<ObjectsResponseDto | ObjectsResponsePrevDto, HttpError<unknown>> = {},
) => ({
  queryKey: groupId ? [OBJECTS, { group: groupId }] : [OBJECTS, OBJECTS_ALL],
  queryFn: () => requests.getObjects({ group: groupId }),
  ...config,
});

export const getObjects = (
  params?: ObjectsParamsDto,
  config: UseQueryOptions<ObjectsResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: params ? [OBJECTS, params] : [OBJECTS, OBJECTS_ALL],
  queryFn: () => requests.getObjects(params),
  ...config,
});

export const getObjectsWithPrevious = (
  params?: ObjectsParamsDto,
  previous?: ObjectsResponseDto,
  config: UseQueryOptions<ObjectsResponsePrevDto, HttpError<unknown>> = {},
) => ({
  queryKey: params ? [OBJECTS, params] : [OBJECTS, OBJECTS_ALL],
  queryFn: () => requests.getObjectsWithPrevious(params, previous),
  ...config,
});

export const getObjectsGroups = (
  objectIds: number[],
  config: UseQueryOptions<ObjectsExtendedDataResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [OBJECTS, OBJECTS_GROUPS],
  queryFn: () =>
    requests.getObjectsExtendedData({
      objects: objectIds,
      request: [ExtendedDataOptionsDto.GROUPS],
    }),
  ...config,
});

export const getObjectsExtendedData = (
  data: ObjectsExtendedDataDto,
  config: UseQueryOptions<ObjectsExtendedDataResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [OBJECTS_EXTENDED_DATA, { data }],
  queryFn: () => requests.getObjectsExtendedData(data),
  ...config,
});

export const getGeocoding = (
  objectId: number,
  params: GeocodingParams,
  config: UseQueryOptions<GeocodingResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [GEOCODING, { objectId }],
  queryFn: () => requests.getGeocoding(params),
  ...config,
});

export const getGeocodingAddress = (
  params: GeocodingParams,
  config: UseQueryOptions<GeocodingResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [GEOCODING, params],
  queryFn: () => requests.getGeocoding(params),
  ...config,
});

export const getVehiclesTypes = (config: UseQueryOptions<string[], HttpError<unknown>> = {}) => ({
  queryKey: VEHICLE_TYPES,
  queryFn: () => requests.getVehiclesTypes(),
  ...config,
});

export const updateCommentAndVehicle = (
  config: UseMutationOptions<unknown, HttpError<unknown>, UpdateObjectDto> = {},
) => ({
  mutationFn: requests.updateCommentAndVehicle,
  ...config,
  onSuccess: (response: unknown, payload: UpdateObjectDto) => {
    queryClient.setQueriesData([OBJECTS], (current: unknown) => {
      const data = current as ObjectsResponseDto;
      const objectIndex = data.data.findIndex(i => i.objectId === payload.objectId);

      return {
        ...data,
        data: data.data.map((i, index) => {
          if (index === objectIndex) {
            return {
              ...i,
              comment: payload.comment,
              type: payload.type,
            };
          }

          return i;
        }),
      };
    });
  },
});

export const updateObjectWithVRN = (
  config: UseMutationOptions<unknown, HttpError<ErrorType>, UpdateObjectVRNDto> = {},
) => {
  const { showToast } = useToast();
  const { t } = useTranslation();

  return {
    mutationFn: requests.updateObjectWithVRN,
    ...config,
    onSuccess: () => {
      queryClient.invalidateQueries(OBJECTS);
      showToast({ content: t('objectUpdateSuccess') });
    },
    onError: (err: HttpError<ErrorType>) => {
      showToast({
        content: err.response?.data?.status === 409 ? t('vrnIsTaken') : t('objectUpdateFailure'),
        type: Status.Negative,
      });
    },
  };
};

export const getRoadCard = (
  payload: RoadCardPayload,
  config: UseQueryOptions<getRoadCardResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [ROAD_CARD, { payload }],
  queryFn: () => requests.getRoadCard(payload),
  ...config,
});

export const getIgnitionControllsHistory = (
  objectId: string,
  config: UseQueryOptions<ControllsHistoryResponseDto, HttpError<unknown>> = {},
) => ({
  queryKey: [IGNITION_HISTORY, { objectId }],
  queryFn: () => requests.getIgnitionControllsHistory(objectId),
  ...config,
});

export const updateIgnitionControlls = (
  config: UseMutationOptions<unknown, HttpError<unknown>, UpdateControllsIgnitionDto> = {},
) => ({
  mutationFn: requests.updateIgnitionControlls,
  ...config,
  onSuccess: () => {
    queryClient.invalidateQueries(IGNITION_HISTORY);
  },
});
