import BackIcon from 'assets/arrow-left.svg';
import CarIcon from 'assets/car.svg';
import CloseIcon from 'assets/close.svg';
import EyeIcon from 'assets/eye.svg';
import ArrowBottom from 'assets/icons/arrow-bottom.svg';
import ArrowTop from 'assets/icons/arrow-top.svg';
import SearchIcon from 'assets/icons/search.svg';
import MenuIcon from 'assets/menu.svg';
import PencilIcon from 'assets/pencil.svg';
import RefreshIcon from 'assets/refresh.svg';
import { memo, ReactElement } from 'react';
import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components/macro';
import { useAppContext } from 'utils/appContext';

export type Icon =
  | 'eye'
  | 'car'
  | 'pencil'
  | 'refresh'
  | 'menu'
  | 'search'
  | 'back'
  | 'close'
  | 'arrowTop'
  | 'arrowBottom'
  | ReactElement;

type IconButtonProps = {
  icon: Icon;
  disabled?: boolean;
  onClick?: () => void;
  onPointerEnter?: () => void;
  dark?: boolean;
  primary?: boolean;
  width?: string;
  noBorder?: boolean;
};

type IconWrapperProps = React.HTMLProps<HTMLButtonElement> & {
  disabled?: boolean;
  dark?: boolean;
  primary?: boolean;
  width?: string;
  height?: string;
  noBorder?: boolean;
};

const Wrapper = styled.div<IconWrapperProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ width }) => width || '42px'};
  width: ${({ width }) => width || '42px'};
  height: ${({ width }) => width || '42px'};
  border-radius: 6px;
  transition: 0.2s ease;

  ${p => !p.disabled && `cursor: pointer;`}
  background: ${p =>
    p.dark && p.disabled ? p.theme.colors.gray500 : p.theme.colors.primaryBackground};
  border: ${p =>
    p?.noBorder
      ? 'unset'
      : `1px solid ${p.disabled ? p.theme.colors.gray200 : p.theme.colors.gray300}`};

  &:hover:not([disabled]),
  &:active {
    border-color: ${p => p.theme.colors.primary600};
  }

  &:active {
    background: ${p => p.theme.colors.primary200};
  }

  ${p =>
    p.primary &&
    css`
      background: ${p.theme.colors.primary600};
      border: 1px solid transparent;
    `}
`;

const TextIconWrapper = styled(Wrapper)`
  path {
    fill: ${p => (p.disabled ? p.theme.colors.gray300 : p.theme.colors.gray500)};
  }

  ${p =>
    p.primary &&
    css`
      path {
        fill: ${p => p.theme.colors.white};
      }
    `}
`;

const IconWrapper = styled(Wrapper)`
  svg {
    height: 2.5rem;
    width: auto;
    color: ${p => (p.disabled ? p.theme.colors.gray300 : p.theme.colors.gray500)};

    ${p =>
      p.primary &&
      css`
        color: ${p.theme.colors.white};
      `}
  }
`;

const allowedIcons = {
  eye: EyeIcon,
  car: CarIcon,
  pencil: PencilIcon,
  refresh: RefreshIcon,
  menu: MenuIcon,
  search: SearchIcon,
  back: BackIcon,
  close: CloseIcon,
  arrowTop: ArrowTop,
  arrowBottom: ArrowBottom,
};

const IconButton = ({
  icon,
  disabled,
  onClick,
  onPointerEnter,
  dark,
  primary,
  width,
  noBorder,
}: IconButtonProps) => {
  const { darkMode } = useAppContext();

  if (typeof icon === 'string') {
    return (
      <TextIconWrapper
        role="button"
        disabled={disabled}
        onClick={onClick}
        onPointerEnter={onPointerEnter}
        dark={dark || darkMode}
        primary={primary}
        width={width}
        noBorder={noBorder}
      >
        <SVG src={allowedIcons[icon]} />
      </TextIconWrapper>
    );
  } else {
    return (
      <IconWrapper
        role="button"
        disabled={disabled}
        onClick={onClick}
        onPointerEnter={onPointerEnter}
        dark={dark || darkMode}
        primary={primary}
        noBorder={noBorder}
      >
        {icon}
      </IconWrapper>
    );
  }
};

export default memo(IconButton);
