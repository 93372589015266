import Button from 'components/Button';
import Box from 'components/Dashboard/Box';
import Desc from 'components/Dashboard/Desc';
import InfoBanner from 'components/InfoBanner';
import { Component, ErrorInfo, memo, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorInfo?: ErrorInfo;
  error?: Error;
}

const StyledWrapper = styled.div`
  margin: 30px;
  margin-top: 200px !important;
  word-break: break-all;

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    margin-top: 50px !important;
  }
`;

class ErrorBoundary extends Component<Props & WithTranslation, State> {
  public state: State = {
    hasError: false,
    error: undefined,
    errorInfo: undefined,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('Uncaught error:', error, errorInfo);
    this.setState(state => ({ ...state, error, errorInfo }));
  }

  private refreshCache() {
    caches.keys().then(cacheNames => {
      cacheNames.forEach(value => {
        caches.delete(value);
      });
    });

    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);

    console.log('CACHE CLEARED');
  }

  public render() {
    if (this.state.hasError) {
      return (
        <StyledWrapper>
          <InfoBanner>
            <>
              <h3>{this.props.t('somethingWentWrong')}</h3>

              <Button className={'ml-3'} secondary onClick={() => this.refreshCache()}>
                {this.props.t('refresh')}
              </Button>

              {!!this.state.errorInfo && (
                <Box className={'mt-3'}>
                  <h4>Error code:</h4>
                  <Desc className={'mt-1'}>
                    <code>
                      {this.state.error?.name}: {this.state.error?.message}
                    </code>
                  </Desc>
                  <Desc className={'mt-1'}>
                    <code>{JSON.stringify(this.state.errorInfo)}</code>
                  </Desc>
                </Box>
              )}
            </>
          </InfoBanner>
        </StyledWrapper>
      );
    }

    return this.props.children;
  }
}

export default memo(withTranslation()(ErrorBoundary));
