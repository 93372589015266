import BorderBox from 'components/Dashboard/BorderBox';
import { InfoBannerTypes } from 'components/InfoBanner';
import styled from 'styled-components';

const Wrapper = styled(BorderBox)<{ type?: InfoBannerTypes }>`
  color: ${p =>
    p.type === InfoBannerTypes.ERROR
      ? p.theme.colors.error
      : p.type === InfoBannerTypes.WARNING
      ? p.theme.colors.orange400
      : p.theme.colors.text};

  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.3px;
  border: 1px solid
    ${p =>
      p.type === InfoBannerTypes.ERROR
        ? p.theme.colors.primary200
        : p.type === InfoBannerTypes.WARNING
        ? p.theme.colors.orange200
        : p.theme.colors.gray300};

  background-color: ${p =>
    p.type === InfoBannerTypes.ERROR
      ? p.theme.colors.primary100
      : p.type === InfoBannerTypes.WARNING
      ? p.theme.colors.orange100
      : p.theme.colors.secondaryBackground};
`;

const StyledInfoBanner = {
  Wrapper,
};

export default StyledInfoBanner;
