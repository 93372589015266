import { Coordinates, LocalStorageKeys, MapTypes } from 'common';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';

import { DisplayingObjectsOptions } from '../../MapSettings/getMapSettings';
import Context from './MainLayoutContext';

type MainLayoutContextProviderProps = {
  children: ReactNode;
};

const MainLayoutContextProvider = ({ children }: MainLayoutContextProviderProps) => {
  const [cookies, setCookie] = useCookies(['object-filtering']);
  const [showMapSettings, setShowMapSettings] = useState(false);
  const [mapType, setMapType] = useState<MapTypes>();
  const [isAutomaticRefresh, setIsAutomaticRefresh] = useState(false);
  const [groupObjectsOn, setGroupObjectsOn] = useState(false);
  const [plateNamesOn, setPlateNamesOn] = useState(false);
  const [vehiclesNameShown, setVehiclesNameShown] = useState(true);
  const [vehiclesIconsType, setVehiclesIconsType] = useState<string>(
    DisplayingObjectsOptions.ARROWS,
  );
  const [trafficLayerOn, setTrafficLayerOn] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<string>();
  const [objectsGroupId, setObjectsGroupId] = useState('');
  const [objectInfoId, setObjectInfoId] = useState<number>();
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [scrollwheelEnabled, setScrollwheelEnabled] = useState<boolean>(true);
  const [centerOnBounds, setCenterOnBounds] = useState<google.maps.LatLngBounds | null>(null);

  const filteredObjectIds = useMemo(() => {
    const data = cookies['object-filtering'];

    if (typeof data === 'string') return [];

    return Object.keys(cookies['object-filtering'] ?? {});
  }, [cookies]);

  const [fitMapBounds, setFitMapBounds] = useState(true);
  const [isInfoboxVisible, setIsInfoboxVisible] = useState<boolean>(false);
  const [isObjectSelectedFromList, setIsObjectSelectedFromList] = useState<boolean>(false);
  const [bubblesCoordinates, setBubblesCoordinates] = useState<Coordinates>();
  const [showMobileModal, setShowMobileModal] = useState(false);

  useEffect(() => {
    const lsIsAutomaticRefresh = localStorage.getItem(LocalStorageKeys.IS_AUTOMATIC_REFRESH);
    setIsAutomaticRefresh(lsIsAutomaticRefresh === 'true');

    const lsGroupObjectsOn = localStorage.getItem(LocalStorageKeys.GROUP_OBJECTS_ON);
    setGroupObjectsOn(lsGroupObjectsOn === 'true');

    const lsPlateNamesOn = localStorage.getItem(LocalStorageKeys.PLATE_NAMES_ON);
    setPlateNamesOn(lsPlateNamesOn === 'true');

    const lsVehiclesIconsType = localStorage.getItem(LocalStorageKeys.VEHICLES_ICONS_TYPE);
    setVehiclesIconsType(lsVehiclesIconsType ?? DisplayingObjectsOptions.ARROWS);

    const lsTrafficLayerOn = localStorage.getItem(LocalStorageKeys.TRAFFIC_LAYER_ON);
    setTrafficLayerOn(lsTrafficLayerOn === 'true');

    const lsHideObjectsNames = localStorage.getItem(LocalStorageKeys.HIDE_OBJECTS_NAMES);
    setVehiclesNameShown(lsHideObjectsNames !== 'true');

    const lsObjectsGroupId = localStorage.getItem(LocalStorageKeys.OBJECTS_GROUP_ID);
    lsObjectsGroupId && setObjectsGroupId(lsObjectsGroupId);

    const lsMapType = localStorage.getItem(LocalStorageKeys.MAP_TYPE);
    setMapType((lsMapType as MapTypes) ?? MapTypes.GOOGLE);

    const statusFilters = JSON.parse(localStorage.getItem(LocalStorageKeys.STATUS_FILTERS)!);
    statusFilters && setStatusFilters(statusFilters);
  }, []);

  const setFilterdObjectIds = useCallback(
    (ids?: Record<string, boolean>) => setCookie('object-filtering', ids),
    [setCookie],
  );

  const handleSelectedVehicleId = useCallback((id?: string) => {
    setSelectedVehicleId(id);
  }, []);

  const handleMapType = useCallback((type: MapTypes) => {
    setMapType(type);
    localStorage.setItem(LocalStorageKeys.MAP_TYPE, type);
  }, []);

  return (
    <Context.Provider
      value={{
        showMapSettings,
        setShowMapSettings,
        isAutomaticRefresh,
        setIsAutomaticRefresh,
        groupObjectsOn,
        setGroupObjectsOn,
        plateNamesOn,
        setPlateNamesOn,
        vehiclesNameShown,
        setVehiclesNameShown,
        vehiclesIconsType,
        setVehiclesIconsType,
        trafficLayerOn,
        setTrafficLayerOn,
        selectedVehicleId,
        setSelectedVehicleId: handleSelectedVehicleId,
        objectsGroupId,
        setObjectsGroupId,
        filteredObjectIds,
        setFilterdObjectIds,
        statusFilters,
        setStatusFilters,
        mapType,
        setMapType: handleMapType,
        fitMapBounds,
        setFitMapBounds,
        objectInfoId,
        setObjectInfoId,
        isInfoboxVisible,
        setIsInfoboxVisible,
        isObjectSelectedFromList,
        setIsObjectSelectedFromList,
        bubblesCoordinates,
        setBubblesCoordinates,
        showMobileModal,
        setShowMobileModal,
        scrollwheelEnabled,
        setScrollwheelEnabled,
        centerOnBounds,
        setCenterOnBounds,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default MainLayoutContextProvider;
