import styled from 'styled-components/macro';

const Box = styled.div<{
  m?: string;
  mt?: string;
  mr?: string;
  ml?: string;
  mb?: string;
  p?: string;
  bgc?: string;
}>`
  background-color: ${p => p?.bgc ?? p.theme.colors.secondaryBackground};
  padding: ${p => p?.p ?? p.theme.spacing.regular};
  border-radius: 4px;
  margin: ${p => p?.m ?? 'unset'};
  margin-top: ${p => p?.mt ?? 'unset'};
  margin-right: ${p => p?.mr ?? 'unset'};
  margin-left: ${p => p?.ml ?? 'unset'};
  margin-bottom: ${p => p?.mb ?? 'unset'};
`;

export default Box;
