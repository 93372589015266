import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';

enum AvailableDateTemplates {
  DAY = '1d',
  WEEK = '7d',
  TWO_WEEKS = '14d',
  MONTH = '30d',
}

interface AvailableDateTemplate {
  id: AvailableDateTemplates;
  label: string;
  value: number;
}

/**
 * This function is used to get start and end
 * date for specific day
 * @param day
 * @returns
 */
const getParsedPeriod = (value: number): { start: string; end: string } => {
  const today = moment(new Date());
  const startDate = moment(today).subtract(value, 'days');

  return {
    start: startDate.utc().startOf('day').toISOString(),
    end: today.utc().endOf('day').toISOString(),
  };
};

function useRoadCard() {
  /**
   * Returns parameters for road card request
   */
  const [dateTime, setDateTime] = useState<{ start: string; end: string }>(getParsedPeriod(0));

  /**
   * Returns selected date template id
   */
  const [currentPeriod, setCurrentPeriod] = useState<AvailableDateTemplates>(
    AvailableDateTemplates.DAY,
  );

  /**
   * This function is used to update the dateTime object
   */
  const handleSetPeriod = useCallback(
    (period: AvailableDateTemplate) => {
      setCurrentPeriod(period.id);
      setDateTime(getParsedPeriod(period.value));
    },
    [setCurrentPeriod, setDateTime],
  );

  /**
   * Returns list of available date templates
   * used to control road card period
   */
  const AVAILABLE_DATE_TEMPLATES = useMemo(
    (): AvailableDateTemplate[] => [
      { id: AvailableDateTemplates.DAY, label: '1D', value: 0 },
      { id: AvailableDateTemplates.WEEK, label: '7D', value: 7 },
      { id: AvailableDateTemplates.TWO_WEEKS, label: '14D', value: 14 },
      { id: AvailableDateTemplates.MONTH, label: '30D', value: 30 },
    ],
    [],
  );

  return { AVAILABLE_DATE_TEMPLATES, currentPeriod, dateTime, handleSetPeriod };
}

export default useRoadCard;
