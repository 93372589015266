import { Coordinates, MapTypes } from 'common';
import { createContext, Dispatch, SetStateAction } from 'react';

import { DisplayingObjectsOptions } from '../../MapSettings/getMapSettings';

export type MainLayoutContextProps = {
  showMapSettings: boolean;
  setShowMapSettings: (show: boolean) => void;
  isAutomaticRefresh?: boolean;
  setIsAutomaticRefresh?: (isAutomatic: boolean) => void;
  groupObjectsOn?: boolean;
  setGroupObjectsOn: (on: boolean) => void;
  plateNamesOn?: boolean;
  setPlateNamesOn?: (on: boolean) => void;
  vehiclesNameShown: boolean;
  setVehiclesNameShown: (on: boolean) => void;
  vehiclesIconsType?: string;
  setVehiclesIconsType?: (on: string) => void;
  trafficLayerOn?: boolean;
  setTrafficLayerOn?: (on: boolean) => void;
  selectedVehicleId?: string;
  setSelectedVehicleId?: (id?: string) => void;
  objectsGroupId?: string;
  setObjectsGroupId?: (id: string) => void;
  filteredObjectIds?: string[];
  setFilterdObjectIds?: (ids?: Record<string, boolean>) => void;
  statusFilters?: string[];
  setStatusFilters?: (labels: string[]) => void;
  mapType?: MapTypes;
  setMapType: (type: MapTypes) => void;
  fitMapBounds: boolean;
  setFitMapBounds: (fit: boolean) => void;
  objectInfoId?: number;
  setObjectInfoId: (id?: number) => void;
  isInfoboxVisible: boolean | undefined;
  setIsInfoboxVisible: (on: boolean) => void;
  isObjectSelectedFromList?: boolean;
  setIsObjectSelectedFromList: (on: boolean) => void;
  bubblesCoordinates?: Coordinates;
  setBubblesCoordinates: (coordinates: Coordinates) => void;
  showMobileModal?: boolean;
  setShowMobileModal: (on: boolean) => void;
  scrollwheelEnabled: boolean;
  setScrollwheelEnabled: (on: boolean) => void;
  centerOnBounds: google.maps.LatLngBounds | null;
  setCenterOnBounds: Dispatch<SetStateAction<google.maps.LatLngBounds | null>>;
};

export const MainLayoutContext = createContext<MainLayoutContextProps>({
  showMapSettings: false,
  setShowMapSettings: () => {},
  isAutomaticRefresh: false,
  setIsAutomaticRefresh: () => {},
  groupObjectsOn: false,
  setGroupObjectsOn: () => {},
  plateNamesOn: false,
  setPlateNamesOn: () => {},
  vehiclesNameShown: true,
  setVehiclesNameShown: () => {},
  vehiclesIconsType: DisplayingObjectsOptions.ARROWS,
  setVehiclesIconsType: () => {},
  trafficLayerOn: false,
  setTrafficLayerOn: () => {},
  selectedVehicleId: undefined,
  setSelectedVehicleId: () => {},
  objectsGroupId: undefined,
  setObjectsGroupId: () => {},
  mapType: undefined,
  setMapType: () => {},
  fitMapBounds: false,
  setFitMapBounds: () => {},
  setObjectInfoId: () => {},
  isInfoboxVisible: false,
  setIsInfoboxVisible: () => {},
  isObjectSelectedFromList: false,
  setIsObjectSelectedFromList: () => {},
  bubblesCoordinates: undefined,
  setBubblesCoordinates: () => {},
  showMobileModal: false,
  setShowMobileModal: () => {},
  statusFilters: undefined,
  setStatusFilters: () => {},
  scrollwheelEnabled: true,
  setScrollwheelEnabled: () => {},
  centerOnBounds: null,
  setCenterOnBounds: () => {},
});

export default MainLayoutContext;
