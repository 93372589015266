import { createGlobalStyle } from 'styled-components/macro';

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 62.5%;
    line-height: 1.5;
  }

  body {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: ${p => p.theme.colors.primaryBackground};
    font-size: 1.6rem;

    @media (max-width: ${p => p.theme.breakpoints.md}px) {
      background-color: unset;
      }
  }

  body.fontLoaded {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4.8rem;
    background: ${({ theme }) => theme.colors.gray200};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4.8rem;
    background: ${({ theme }) => theme.colors.gray400};
  }

  @media (max-width: ${p => p.theme.breakpoints.md}px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

  .mt-1 {
      margin-top: 0.5rem;
  }

  .mt-2 {
      margin-top: 1rem;
  }

  .mt-3 {
      margin-top: 1.5rem;
  }

  .mb-1 {
      margin-bottom: 0.5rem;
  }

  .mb-2 {
      margin-bottom: 1rem;
  }

  .mb-3 {
      margin-bottom: 1.5rem;
  }

  .p-1 {
      padding: 0.5rem;
  }

  .p-2 {
      padding: 1rem;
  }

  .p-3 {
      padding: 1.5rem;
  }

  @media print {
    body * {
        visibility: hidden;
    }

    #section-to-print, #section-to-print * {
        visibility: visible;
    }

    #section-to-print {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
  }
`;
