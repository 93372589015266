import styled from 'styled-components/macro';

const BorderBox = styled.div<{ m?: string; p?: string; bgc?: string }>`
  padding: ${p => p?.p ?? p.theme.spacing.regular};
  border-radius: 4px;
  margin: ${p => p?.m ?? 'unset'};
  border: 1px solid ${p => p?.bgc ?? p.theme.colors.gray200};
`;

export default BorderBox;
