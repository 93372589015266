import Flex from 'components/Dashboard/Flex';
import StyledInfoBanner from 'components/InfoBanner/style';
import React, { ComponentPropsWithRef } from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';

export enum InfoBannerTypes {
  WARNING = 'warning',
  ERROR = 'error',
}

const InfoBanner: React.FC<ComponentPropsWithRef<'div'> & { type?: InfoBannerTypes }> = props => {
  const { children } = props;
  return (
    <StyledInfoBanner.Wrapper {...props}>
      <Flex gap={5}>
        <IoMdInformationCircleOutline fontSize={'large'} />
        {children}
      </Flex>
    </StyledInfoBanner.Wrapper>
  );
};

export default InfoBanner;
