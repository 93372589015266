export enum AllowedModules {
  DISABLE_REASON_VRN = 'disable-reason-vrn',
  DRIVER_LIMITED = 'driver-limited',
  DRIVER_STATUS = 'driver-status',
  INTEGRATIONS_SAMSUNG = 'integration-samsung',
  PUESC_ACCESS = 'puesc-access',
  PUESC_LIMITED = 'puesc-limited',
  REPORTS_DISTANCE_COMBUSTION = 'karta-drogowa-fuel',
  REPORTS_DISTANCE_WORKTIME = 'distance-worktime',
  REPORTS_FUEL_TAKS = 'fuel-tanks',
  REPORTS_LOGBOOK = 'karta-drogowa',
  REPORTS_LOGBOOK_RESTORE_XLS = 'karta-drogowa-resotre-xls',
  REPORTS_LOGBOOK_SIMPLE = 'karta-drogowa-simple',
  REPORTS_LOGBOOK_SIMPLE_XLS = 'karta-drogowa-simple-xls',
  REPORTS_LOGBOOK_WORKTIME = 'karta-drogowa-worktime',
  REPORTS_LOGBOOK_WORKTIME_DRIVER_FUEL = 'karta-drogowa-driver-fuel',
  REPORTS_LOGBOOK_WORKTIME_XLS = 'karta-drogowa-worktime-xls',
  REPORTS_LOGBOOK_XLS = 'karta-drogowa-xls',
  REPORTS_MILOG = 'milog',
  REPORTS_MILOG_CSV_CROSS = 'milog-csv-cross',
  REPORTS_MILOG_SIMPLE = 'milog-simple',
  REPORTS_MILOG_XLS = 'milog-xls',
  REPORTS_MILOG_XLS_DRIVERS = 'milog-xls-drivers',
  REPORTS_MILOG_XML = 'milog-xml',
  REPORTS_RETARDER = 'retarder',
  REPORTS_SPECIAL = 'special',
  REPORTS_SPECIAL_SIMPLE = 'special-simple',
  REPORTS_TACHO = 'tacho',
  REPORTS_TEMPERATURE_SENSOR = 'temperature-sensor',
  REPORTS_WORKTIME_CALENDAR = 'calendar-worktime',
  REPORTS_TEMPOMAT = 'tempomat',
  REPORTS_VAT_US = 'vat-us',
  REPORTS_VAT_US_XLS = 'vat-us-xls',
  REPORTS_GRAPHICAL_PREVIEW = 'reports-graphical-preview',
  SETTINGS_NOTIFICATIONS_BATTERY_VOLTAGE = 'login-notifications-voltage',
  SETTINGS_NOTIFICATIONS_ENGINE_START = 'login-notifications-engine',
  SETTINGS_NOTIFICATIONS_RPM = 'login-notifications-rpm',
  SETTINGS_NOTIFICATIONS_SERVICE = 'login-notifications-service',
  SETTINGS_NOTIFICATIONS_SPEED_LIMIT = 'login-notifications-speed',
  SETTINGS_NOTIFICATIONS_TEMPERATURE = 'login-notifications-temperature',
  SETTINGS_NOTIFICATIONS_VEHICLE_UNPLUG = 'login-notifications-unplug',
  SETTINGS_REPORTS_WORKTIME_DISTANCE = 'distance-worktime',
  SPEDITOR_SHARING = 'speditor-sharing',
  VEHICLE_COMMAND_IGNITION = 'vehicle-command-ignition',
  MAP_PROVIDER_HERE = 'map-provider-here',
  ZONES_MANAGEMENT = 'zones-management',
}
