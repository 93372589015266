import './styles.css';

import { GoogleMap, MarkerClusterer, TrafficLayer, useJsApiLoader } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { AllowedModules, Coordinates } from 'common';
import { initialMapCenter } from 'common/InitialMapCenter';
import LoadingIndicator from 'components/LoadingIndicator';
import MainLayoutContext from 'components/MainLayout/context/MainLayoutContext';
import { Overlay } from 'components/Overlay';
import StatusExplanatory from 'modules/driver/components/StatusExplanatory';
import { statusElementType } from 'modules/driver/dtos';
import { zoomLevels } from 'modules/objects/utils/zoomLevels';
import { memo, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterDefined, useCheckModuleAccess, useWindowSize } from 'utils';

import ClusterInfoBox from './ClusterInfoBox';
import MapSpy from './MapSpy';
import Marker from './Marker';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';

export type MarkerProps = {
  position: Coordinates;
  objectId?: number;
  title?: string;
  icon?: string;
  rotation?: number;
  color?: string;
  status?: number;
  onClick?: (objectId?: number) => void;
  driverStatuses?: statusElementType[];
};

type MapProps = {
  markers?: MarkerProps[];
  zoom?: number;
  objectInfoPosition?: Coordinates;
  cluster?: boolean;
  showTrafficLayer?: boolean;
  children?: ReactNode;
  hideMarkersInfo?: boolean;
  hideMapControls?: boolean;
  statuses?: statusElementType[];
};

const Map = ({
  markers = [],
  objectInfoPosition,
  showTrafficLayer = false,
  cluster = false,
  zoom,
  children,
  hideMarkersInfo = false,
  statuses,
  hideMapControls = false,
}: MapProps) => {
  const { t } = useTranslation();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey,
    libraries: ['drawing'],
    id: 'google-map',
  });
  const { isMobile } = useWindowSize();
  const google = window.google;

  const [clustersInfo, setClustersInfo] = useState<
    { position?: google.maps.LatLng; markers: MarkerProps[] }[]
  >([]);

  const { isObjectSelectedFromList, showMobileModal, scrollwheelEnabled } =
    useContext(MainLayoutContext);

  const checkModuleAccess = useCheckModuleAccess();
  const isDriverStatusAccount = checkModuleAccess(AllowedModules.DRIVER_STATUS);

  // update clusters info
  useEffect(() => {
    if (!markers.length) return;

    setClustersInfo(current =>
      current.map(cluster => ({
        ...cluster,
        markers: filterDefined(
          cluster.markers.map(
            clusterMarker =>
              markers.find(marker => clusterMarker?.objectId === marker?.objectId) as MarkerProps,
          ),
        ),
      })),
    );
  }, [markers]);

  const mapOptions: google.maps.MapOptions = useMemo(() => {
    if (!google) return {};
    if (hideMapControls) return { disableDefaultUI: true };

    if (isMobile) {
      return {
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_LEFT,
        },
        zoomControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        streetViewControl: false,
        fullscreenControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_RIGHT,
        },
      };
    }

    return {
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_CENTER,
      },
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      scrollwheel: scrollwheelEnabled,
      fullscreenControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_RIGHT,
      },
    };
  }, [isMobile, google, scrollwheelEnabled, hideMapControls]);

  const mapCenter = useMemo(() => {
    if (objectInfoPosition) return objectInfoPosition;

    return initialMapCenter;
  }, [objectInfoPosition]);

  const mapZoom = useMemo(() => {
    if (zoom) return zoom;
    if (isObjectSelectedFromList || markers.length === 1) return zoomLevels.close;

    return zoomLevels.regular;
  }, [isObjectSelectedFromList, markers, zoom]);

  const handleClusteringEnd = useCallback(
    (markerClusterer: Clusterer) => {
      const info = markerClusterer.clusters.map(cluster => ({
        position: cluster.getCenter(),
        markers: cluster.markers.map(
          marker => markers.find(m => m.objectId === +(marker.getTitle() ?? 0)) as MarkerProps,
        ),
      }));

      setClustersInfo(info);
    },
    [markers],
  );

  if (loadError) return <>{t('somethingWentWrong')}</>;

  return (
    <>
      {showMobileModal && <Overlay />}
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={mapCenter}
          zoom={mapZoom}
          options={mapOptions}
          id="google-map"
        >
          {isDriverStatusAccount && statuses && <StatusExplanatory statuses={statuses} />}
          {!cluster &&
            markers.map((marker, index) => <Marker key={`marker-${index}`} {...marker} />)}
          {cluster && markers.length && (
            <>
              <MarkerClusterer onClusteringEnd={handleClusteringEnd}>
                {clusterer => (
                  <div>
                    {markers.map((marker, index) => (
                      <Marker key={`clusterMarker-${index}`} {...marker} clusterer={clusterer} />
                    ))}
                  </div>
                )}
              </MarkerClusterer>
              {!hideMarkersInfo &&
                clustersInfo.map((info, index) => (
                  <ClusterInfoBox key={`clusterInfo-${index}`} {...info} />
                ))}
            </>
          )}
          {showTrafficLayer && <TrafficLayer />}
          {children}
          <MapSpy markers={markers} />
        </GoogleMap>
      )}
      {!isLoaded && <LoadingIndicator centerVertically />}
    </>
  );
};

export default memo(Map);
