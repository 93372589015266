import { CustomsServiceListTypes } from 'modules/premium/common/CustomsServiceListTypes';
import { ReportTypes } from 'modules/reports/common';

export const routes = Object.freeze({
  home: '/',
  access: '/access',
  login: '/access/login',
  forgotPassword: '/access/forgot-password',
  resetPassword: (token: string) => `/access/reset-password/${token}`,
  objects: '/app/objects',
  settings: '/app/settings',
  contactDetailsSettings: '/app/settings/profile/contact-details',
  changePasswordSettings: '/app/settings/profile/change-password',
  colorModeSettings: '/app/settings/profile/color-mode',
  speedLimitSettings: '/app/settings/notifications/speed-limit',
  zonesSettings: '/app/settings/notifications/zones',
  engineStartSettings: '/app/settings/notifications/engine-start',
  vehicleUnplugSettings: '/app/settings/notifications/vehicle-unplug',
  serviceSettings: '/app/settings/notifications/service',
  batteryVoltageSettings: '/app/settings/notifications/battery-voltage',
  rpmSettings: '/app/settings/notifications/rpm',
  logBookSettings: '/app/settings/reports/log-book',
  vatUsSettings: '/app/settings/reports/vat-us',
  workingDistanceSettings: '/app/settings/reports/working-distance',
  distanceSourceSettings: '/app/settings/reports/distance-source',
  routeSettings: '/app/settings/route',
  otherObjectsSettings: '/app/settings/others-objects-settings',
  otherMenuBar: '/app/settings/others-menu-bar',
  mapSettings: '/app/settings/map',
  objectsSettings: '/app/settings/objects',
  route: '/app/route',
  zones: '/app/zones',
  reports: '/app/reports',
  reportsForm: '/app/reports/form',
  reportsAutomation: '/app/reports/automation',
  reportsList: '/app/reports/list',
  reportOthersForm: (type: ReportTypes) => `/app/reports/form/others/${type}`,
  reportsPreview: (id: string | number, type: ReportTypes) => `/app/reports/preview/${type}/${id}`,
  reportsPreviewPdf: (id: string | number, type: ReportTypes) =>
    `/app/reports/preview/${type}/${id}/pdf`,
  reportLogbookForm: `/app/reports/form/logbook`,
  reportMilogForm: `/app/reports/form/milog`,
  reportBordercrossForm: `/app/reports/form/bordercross`,
  reportDistanceCombustionForm: `/app/reports/form/distance-combustion`,
  reportRefuelingForm: `/app/reports/form/refueling`,
  reportVatUsForm: `/app/reports/form/vat-us`,
  samsungIntegration: `/app/integrations/samsung`,
  samsungIntegrationForm: '/app/integrations/samsung/form',
  samsungIntegrationList: '/app/integrations/samsung/list',
  driverAuth: `/app/driver`,
  driverMessenger: '/app/driver/messenger',
  driverMap: '/app/driver/map',
  driverCamera: '/app/driver/camera',
  driverGallery: '/app/driver/gallery',
  spedition: '/app/spedition',
  hashError: '/app/hash-error',
  loginsAndGroups: '/app/logins-groups',
  sharing: '/app/sharing',
  chat: 'app/chat',
  changelog: 'app/changelog',
  roadCardPreview: (objectId: number) => `/app/road-card-preview/${objectId}`,
  premiumSentGeo: `/app/premium/sent-geo`,
  premiumEtoll: `/app/premium/e-toll`,
  premiumPuescHistory: (objectId: string | number, type: CustomsServiceListTypes) =>
    `/app/premium/puesc-history/${type}/${objectId}`,
  controllsHistory: (objectId: string | number) => `/app/controlls/history/${objectId}`,
  sharePreviewLink: (hash: string | number) => `/app/sharing/preview/${hash}`,
});
